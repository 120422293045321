import React from 'react';
import {
  useTheme,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import './Footer.scss';
import {useNavigate} from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        position: "fixed", // Fixed footer
        bottom: 0,
        left: "50.05%", // Start from the center
        transform: "translateX(-50%)", // Adjust to make it centered
        width: isMobile ? "100%" : "41%", // Full width on mobile, 50% on larger screens
        height: "60px",
        backgroundColor: "#18AEC5", // Blue background
        padding: "5px 0", // Reduced padding for smaller height
        display: "flex",
        borderRadius: isMobile ? "10px 10px 0px 0px" : "10px",
        justifyContent: "center", // Center items in footer
        alignItems: "center", // Center vertically
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around", // Space items evenly
          alignItems: "center",
          width: "100%", // Full width for the inner box to space items
          maxWidth: "600px", // Optional: set a max width for better appearance on larger screens
        }}
      >
        <FooterButton
          icon="/image/footer/home.svg"
          label="Home"
          handleClick={() => {
            navigate("/");
          }}
        />
        <Box
          className="special-button"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            // border: "2px solid #18AEC5", // Add blue border
            borderRadius: "8px", // Optional: for rounded corners
          }}
        >
          <FooterButton
            icon="/image/footer/cricket.webp"
            special
            handleClick={() => {
              navigate("/inplay");
            }}
          />
          <Typography
            variant="caption"
            sx={{ textAlign: "center", mt: "-18px", color: "white" }}
          >
            Cricket Fight
          </Typography>
        </Box>

        <FooterButton
          icon="/image/footer/mymarket.svg"
          label="My Market"
          handleClick={() => {
            navigate("/my-market");
          }}
        />
        {/* <FooterButton icon="/image/footer/game-controller.svg" label="Casino" />
        <FooterButton icon="/image/footer/account.svg" label="Account" /> */}
      </Box>
    </Box>
  );
};

const FooterButton = ({icon, label, special, handleClick}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: special ? 'relative' : 'static',
        top: special ? '-25px' : '0', // Half out of the footer for special button
      }}
      onClick={() => handleClick()}
    >
      <IconButton
        sx={{
          backgroundColor: special ? 'white' : 'transparent',
          borderRadius: '50%',
          width: special ? 50 : 30, // Reduced size for a smaller look
          height: special ? 50 : 30,
          mb: 1,
        }}
      >
        <img
          src={icon}
          alt={label}
          style={{
            width: special ? 30 : 20, // Adjusted for special buttons
            height: special ? 30 : 20,
            objectFit: 'contain',
            filter: 'invert(100%)', // This makes the icon white
          }}
        />
      </IconButton>
      {label && (
        <Typography
          variant="caption"
          sx={{color: 'white', fontSize: '0.65rem', textAlign: 'center'}} // Smaller text for cleaner look
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
