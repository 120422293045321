import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "./authAxios";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./Routes";

function App() {
  const [token, setToken] = useState(Cookies.get("jwtToken"));
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    setToken(jwtToken);
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
  }, [Cookies.get("jwtToken")]);

  return <AppRoutes />;
}

export default App;
