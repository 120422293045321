import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import GameListItem from '../../../Componant/Gamelistitem.js';
import './match.scss';
import {IconButton, SwipeableDrawer, Tab, Tabs} from '@mui/material';
import BetDrawer from '../../../Componant/BetDrawer/BetDrawer.js';
import axios from '../../../authAxios.js';
import {useRef} from 'react';
import Swal from 'sweetalert2';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {BetPrediction} from './BetPrediction.js';

const IframeRenderer = ({url}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const updateIframeStyles = () => {
      const iframeDocument = iframeRef.current.contentDocument;

      if (iframeDocument) {
        // Adjust the styles as needed
        iframeDocument.body.style.overflow = 'hidden';
        iframeDocument.body.style.maxWidth = '100%';
        iframeDocument.body.style.boxSizing = 'border-box';
        iframeDocument.documentElement.style.height = '100%';
        iframeDocument.documentElement.style.overflowY = 'hidden';
      }
    };

    window.addEventListener('resize', updateIframeStyles);
    updateIframeStyles(); // Initial update on component mount

    return () => {
      window.removeEventListener('resize', updateIframeStyles);
    };
  }, []);

  return (
    <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
      <iframe
        title="Rendered HTML"
        src={url}
        style={{width: '100%', height: '100%', border: 'none'}}
        ref={iframeRef}
      />
    </div>
  );
};

function Match() {
  const params = useParams();
  const sportId = params.sport_id;
  const navigate = useNavigate();
  const [matchDetails, setMatchDetails] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [sessionBets, setSessionBets] = useState([1, 4, 7]);
  const [open, setOpen] = useState(false);
  const [Home, setHome] = useState('');
  const [Away, setAway] = useState('');
  const [bookMaker, setbookMaker] = useState([]);
  const [selectedBet, setSelectedBet] = useState();
  const [market_id, setmarket_id] = useState();
  const [odds, setodds] = useState();
  const [diamondFancy, setdiamondFancy] = useState([]);
  const [positionArr, setPositionArr] = useState({});
  const [oddsPositionArr, setOddsPositionArr] = useState({});
  const [fancyPositionArr, setFancyPositionArr] = useState({No: 0, Yes: 0});
  const [activeTab, setActiveTab] = useState(0);
  const [fancySessionBet, setFancySessionBet] = useState([]);
  const [playedMatchBets, setPlayedMatchBets] = useState([]);
  const [totalUndeclaredBet, setTotalUndeclaredBet] = useState(0);
  const [matchStartTime, setMatchStartTime] = useState();
  // const [scoreCard, setscoreCard] = useState([])

  // const [score, setScore] = useState([]);

  const [isBettingDrawerOpen, setIsBettingDrawerOpen] = useState(false);
  const [latestOddsPosition, setLatestOddsPosition] = useState();
  const [latestBookmakerPosition, setLatestBookmakerPosition] = useState();
  const [betInfo, setBetInfo] = useState({
    type: '',
    team: '',
    rate: '',
    positionIndex: '0',
    betCategory: '',
    placeBetFun: '',
  });
  const [isFancyBettingDrawerOpen, setIsFancyBettingDrawerOpen] =
    useState(false);
  const [fancyBetInfo, setFancyBetInfo] = useState({
    selection_id: '',
    runnerName: '',
    layPrice1: '',
    laySize1: '',
    backPrice1: '',
    backSize1: '',
    type: '',
    placeBetFun: '',
  });
  const match_id = params.match_id;

  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);

  const handleFirstButtonClick = () => {
    console.log('here');
    setIsFirstButtonActive(true);
  };

  const handleIconClick = bet => {
    console.log(bet, fancySessionBet);
    const foundBet = fancySessionBet.find(
      res => res?.fancy_Detail?.RunnerName === bet.RunnerName,
    );
    console.log(foundBet);
    setSelectedBet(foundBet);
    setOpen(true); // Open the popup when icon is clicked
  };

  const handleSecondButtonClick = () => {
    console.log('here1');
    setIsFirstButtonActive(false);
  };

  const handleBettingClicked = (
    type,
    team,
    rate,
    positionIndex,
    betCategory,
    placeBetFun,
  ) => {
    setBetInfo({type, team, rate, positionIndex, betCategory, placeBetFun});
    setIsBettingDrawerOpen(prev => !prev);
  };

  const handleFancyBetClick = (
    selection_id,
    runnerName,
    layPrice1,
    laySize1,
    backPrice1,
    backSize1,
    type,
    placeBetFun,
  ) => {
    setFancyBetInfo({
      selection_id,
      runnerName,
      layPrice1,
      laySize1,
      backPrice1,
      backSize1,
      type,
      placeBetFun,
    });
    setIsFancyBettingDrawerOpen(prev => !prev);
  };

  const handleOddsBetPlaced = async (amount, betInfo_obj) => {
    try {
      console.log(betInfo_obj);
      const inputData = {
        bet_amount: amount,
        bet_on: betInfo_obj.team,
        bet_rate: betInfo_obj.rate,
        opponent_team: betInfo_obj.team == Home ? Away : Home,
        bet_type: betInfo_obj.type,
        position: oddsPositionArr,
        market_id: market_id,
        match_id: match_id,
        user_id: localStorage.getItem('user_id'),
        team1: Home,
        team2: Away,
      };
      console.log(inputData);
      let bet = await axios.post('/transactions/place-odds-bet', inputData);
      if (bet.data.status) {
        // console.log("ODDS API PLACED");
        setOddsPositionArr(bet.data.dataobj.position);
        if (bet.data.dataobj.user_bal)
          localStorage.setItem('player_bal', bet.data.dataobj.user_bal);
        if (bet.data.dataobj.user_exp)
          localStorage.setItem('player_exp', bet.data.dataobj.user_exp);
        window.dispatchEvent(new Event('storage'));
        getMatchBetsOfMatchOfUser();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          background: '#2b2d3a',
          color: '#fff',
          title: 'Bet Placed Successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          background: '#2b2d3a',
          color: '#fff',
          title: bet.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      closeBetDrawer();
    } catch (err) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        background: '#2b2d3a',
        color: '#fff',
        title: err?.response?.data?.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      console.log('ERROR LOG', err?.response?.data?.msg);
      console.log(err);
    }
  };
  const handleBookMakerBetPlaced = async (amount, betInfo_obj) => {
    try {
      try {
        var betPlacedInfo = await axios.post('/transactions/place-bet', {
          bet_amount: amount,
          bet_on: betInfo_obj.team,
          bet_rate: betInfo_obj.rate,
          opponent_team: betInfo_obj.team == Home ? Away : Home,
          bet_type: betInfo_obj.type,
          position: positionArr,
          market_id: market_id,
          match_id: match_id,
          user_id: localStorage.getItem('user_id'),
          team1: Home,
          team2: Away,
        });
      } catch (err) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          background: '#2b2d3a',
          color: '#fff',
          title: err.response.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (betPlacedInfo?.data.status) {
        // console.log("BET PLACED INFO", betPlacedInfo);
        setPositionArr(betPlacedInfo.data.dataobj.position);
        if (betPlacedInfo.data.dataobj.user_bal)
          localStorage.setItem(
            'player_bal',
            betPlacedInfo.data.dataobj.user_bal,
          );
        if (betPlacedInfo.data.dataobj.user_exp)
          localStorage.setItem(
            'player_exp',
            betPlacedInfo.data.dataobj.user_exp,
          );
        window.dispatchEvent(new Event('storage'));
        getMatchBetsOfMatchOfUser();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          background: '#2b2d3a',
          color: '#fff',
          title: 'Bet Placed Successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.log(betPlacedInfo);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: betPlacedInfo.data.msg,
          background: '#2b2d3a',
          color: '#fff',
          showConfirmButton: false,
          timer: 1500,
        });
        closeBetDrawer();
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        background: '#2b2d3a',
        color: '#fff',
        title: err?.response?.data?.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      closeBetDrawer();
      console.log('ERROR LOG', err?.response?.data?.msg);
      console.log(err);
    }

    closeBetDrawer();
  };

  // const getSessionBets = async () => {
  //   try {
  //     const sessionBets = await axios.get("/transactions/getAllSessionBets", {
  //       params: {
  //         match_id,
  //         user_id: localStorage.getItem("user_id"),
  //       },
  //     });
  //     console.log(sessionBets.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleFancybetPlaced = async (amount, betInfo_obj) => {
    try {
      try {
        var betPlacedInfo = await axios.post('/transactions/place-fancy-bet', {
          bet_amount: amount,
          runnerName: betInfo_obj.runnerName,
          bet_rate:
            betInfo_obj.type == 'No'
              ? betInfo_obj.laySize1
              : betInfo_obj.backSize1,
          bet_type: betInfo_obj.type,
          position: fancyPositionArr,
          market_id: market_id,
          match_id: match_id,
          selection_id: betInfo_obj.selection_id,
          user_id: localStorage.getItem('user_id'),
          team1: Home,
          team2: Away,
          fancy_details: betInfo_obj,
        });

        // getSessionBets();
        if (betPlacedInfo?.data.status) {
          console.log('BET PLACED INFO', betPlacedInfo);
          if (betPlacedInfo.data.dataobj.user_bal)
            localStorage.setItem(
              'player_bal',
              betPlacedInfo.data.dataobj.user_bal,
            );
          if (betPlacedInfo.data.dataobj.user_exp)
            localStorage.setItem(
              'player_exp',
              betPlacedInfo.data.dataobj.user_exp,
            );
          window.dispatchEvent(new Event('storage'));
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            background: '#2b2d3a',
            color: '#fff',
            title: 'Bet Placed Successfully',
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            background: '#2b2d3a',
            color: '#fff',
            title: betPlacedInfo.data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
          closeBetDrawer();
        }
        getFancyBet();
      } catch (error) {
        closeBetDrawer();
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          background: '#2b2d3a',
          color: '#fff',
          title: error.response.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      }
    } catch (error) {
      closeBetDrawer();
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        background: '#2b2d3a',
        color: '#fff',
        title: error.response.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(error);
    }
    closeBetDrawer();
  };
  const totalAmountUndeclaredBet = uBets => {
    return uBets.reduce((count, i) => {
      if (i.fancy_result == 'undeclared') {
        return count + Number(i.bet_amount);
      }
      return count;
    }, 0);
  };
  const getFancyBet = async () => {
    try {
      const response = await axios.get('/transactions/getAllSessionBets', {
        params: {
          match_id,
          user_id: localStorage.getItem('user_id'),
        },
      });
      if (response.data.status) {
        const sortedData = response.data.dataobj.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
        setFancySessionBet(sortedData);
        setTotalUndeclaredBet(totalAmountUndeclaredBet(response.data.dataobj));
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMatchBetsOfMatchOfUser = async () => {
    try {
      const resp = await axios.get('/users/getMatchBetsOfMatchOfUser', {
        params: {
          match_id,
          user_id: localStorage.getItem('user_id'),
        },
      });
      if (resp.data?.status) {
        const sortedData = resp.data.dataobj.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
        console.log(sortedData);
        setPlayedMatchBets(sortedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const odds = playedMatchBets.filter(item => item.type === 'odds');
    const bookmaker = playedMatchBets.filter(item => item.type === 'bookmaker');
    odds.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    bookmaker.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setLatestOddsPosition(odds[0]?.position);
    setLatestBookmakerPosition(bookmaker[0]?.position);
  }, [playedMatchBets]);

  useEffect(() => {
    getMatchBetsOfMatchOfUser();
    const getMatchDetails = async () => {
      try {
        let res = await axios.get(`/matches/getMatchDetails/${match_id}`);
        // console.log("Market List", res.data.dataobj[0].marketId);
        if (res.data.status) {
          setMatchDetails(res.data.dataobj);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMatchDetails();
    let useEffectMarket_id = '';
    // getSessionBets();

    const getMarketList = async () => {
      try {
        let res = await axios.get('/t-p/getMarketList', {
          params: {
            match_id,
          },
        });
        // console.log("Market List", res.data.dataobj[0].marketId);
        if (res.data.status) {
          const oddsMarketId = res.data.dataobj.filter(res => {
            return res.marketName === 'Match Odds';
          });
          setmarket_id(oddsMarketId[0].marketId);
          useEffectMarket_id = oddsMarketId[0].marketId;
          setSessionBets(res.data.dataobj);
          getBookMakerMarket();
          getDimandFancy();
          getRunners();
          getOdds();
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMarketList();
    const getPositionForUser = async () => {
      try {
        console.log('THIS API WAS CALLED');
        let res = await axios.get(
          '/transactions/getRecentPositionForTheMatch',
          {
            params: {
              match_id: match_id,
              user_id: localStorage.getItem('user_id'),
            },
          },
        );
        console.log('THIS API RETURED THIS', res.data);
        if (res.data?.status) {
          if (res.data.data.bookmaker) setPositionArr(res.data.data.bookmaker);
          if (res.data.data.odds) setOddsPositionArr(res.data.data.odds);
          if (res.data.data.fancy) setFancyPositionArr(res.data.data.fancy);
        }
      } catch (err) {
        console.log('THIS API RETURED ERROR', err);
        console.log(err);
      }
    };

    const getRunners = async () => {
      try {
        const response = await axios.get('/t-p/getRunners', {
          params: {
            market_id: useEffectMarket_id,
          },
        });

        setMatchStartTime(response.data.dataobj[0].marketStartTime);
        let default_position = {};
        // default_position[response.data.dataobj[0].runners[0]?.runnerName] = 0;
        // default_position[response.data.dataobj[0].runners[1]?.runnerName] = 0;
        const runners = response.data.dataobj[0].runners;
        runners[0]?.runner
          ? setHome(runners[0]?.runner)
          : setHome(runners[0]?.runnerName);
        // console.log("home - ",response.data.dataobj[0].runners[0].runnerName);
        runners[1]?.runner
          ? setAway(runners[1]?.runner)
          : setAway(runners[1]?.runnerName);
        for (let i = 0; i < runners.length; i++) {
          if (
            runners[i]?.runnerName == 'The Draw' ||
            runners[i]?.runner == 'The Draw'
          )
            continue;
          default_position[runners[i]?.runnerName || runners[i]?.runner] = 0;
        }
        // console.log("Position", { runners, default_position });
        setPositionArr(default_position);
        setOddsPositionArr(default_position);
        getPositionForUser();
        // console.log("away - ",response.data.dataobj[0].runners[1].runnerName);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const getBookMakerMarket = async () => {
      try {
        const response = await axios.get('/t-p/bookMakerMarket', {
          params: {
            match_id,
          },
        });
        if (response.data.status) {
          setbookMaker(response.data.dataobj);
          // console.log("res bookmaker", response.data.dataobj);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const getOdds = async () => {
      // console.log("market idddd", market_id);
      try {
        const response = await axios.get('/t-p/getOdds', {
          params: {
            market_id: useEffectMarket_id,
          },
        });

        // console.log("get Oddssss",response);
        if (
          response.data.status &&
          Array.isArray(response.data.dataobj) &&
          response.data.dataobj.length > 0
        ) {
          setodds(response.data.dataobj);

          // console.log("res bookmaker", response.data.dataobj);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const getDimandFancy = async () => {
      try {
        const response = await axios.get('/t-p/diamondFancy', {
          params: {
            match_id,
          },
        });
        // console.log("df", response.data.status);
        if (response.data.status) {
          setdiamondFancy(response.data.dataobj);
          // console.log("diamandfancy", diamondFancy);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getFancyResult = async () => {
      try {
        const response = await axios.get('/t-p/getFancyResult', {
          params: {
            match_id,
          },
        });
        // console.log("df", response.data.status);
        if (response.data.status) {
          // setdiamondFancy(response.data.dataobj);
          // console.log("diamandfancy", diamondFancy);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getFancyBet();

    // const intervalIdGR = setInterval(getRunners, 4000);
    let intervalIdBM = setInterval(getBookMakerMarket, 1000);
    let intervalIOdds = setInterval(getOdds, 1000);
    let intervalDF = setInterval(getDimandFancy, 1000);
    const declareFancyResult = setInterval(getFancyResult, 5000);
    // let intervalScore = setInterval(getScore, 1000);
    // let intervalLiveLine = setInterval(getLiveLine, 1000);

    return () => {
      console.log('THIS WAS TRIGGERD < PAGE EXITED', {
        intervalIdBM,
        intervalIOdds,
        intervalDF,
        declareFancyResult,
        // intervalIdGR,
      });
      clearInterval(intervalIdBM);
      clearInterval(intervalIOdds);
      clearInterval(intervalDF);
      clearInterval(declareFancyResult);
      // clearInterval(intervalIdGR);
    };
  }, []);

  //Render Functions
  const oddsTable = () => {
    return (
      <div>
        <div className="betting">
          <h6>Match Odds</h6>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    fontSize: '11px',
                    width: '60%',
                    textAlign: 'left',
                    color: '#6a6a6a',
                  }}
                >
                  Min : 100.0 | Max : 50000.0
                </th>
                <th
                  style={{
                    width: '20%',
                    backgroundColor: '#80c2f1',
                    fontSize: '14px',
                  }}
                >
                  Back
                </th>
                <th
                  style={{
                    width: '20%',
                    background: '#fbb7c6',
                    fontSize: '14px',
                  }}
                >
                  Lay
                </th>
                {/* <th>Position</th> */}
              </tr>
            </thead>
            <tbody>
              {odds &&
                odds.length &&
                (
                  odds[0].runners.sort(
                    (a, b) => a.sortPriority - b.sortPriority,
                  ) || []
                ).map((item, index) => {
                  if (item.runner === 'The Draw') return;
                  const runnerName =
                    index === 0
                      ? matchDetails[0]?.team1
                      : index === 1
                      ? matchDetails[0]?.team2
                      : 'The Draw';
                  return (
                    <tr>
                      <td style={{textAlign: 'left'}}>
                        <div>
                          <div>{runnerName}</div>
                          <div
                            style={{
                              fontSize: '10px',
                              color:
                                latestOddsPosition &&
                                latestOddsPosition[runnerName] > 0
                                  ? 'green'
                                  : 'red',
                            }}
                          >
                            {latestOddsPosition &&
                            latestOddsPosition[runnerName]
                              ? latestOddsPosition[runnerName]
                              : ''}
                          </div>
                        </div>
                      </td>
                      <td
                        style={{width: '60px', backgroundColor: '#80c2f1'}}
                        onClick={() => {
                          handleBettingClicked(
                            'lagai',
                            index === 0
                              ? matchDetails[0]?.team1
                              : index === 1
                              ? matchDetails[0]?.team2
                              : 'The Draw',
                            item?.ex?.availableToBack[0]?.price,
                            index,
                            'odds',
                            handleOddsBetPlaced,
                          );
                        }}
                      >
                        <div>
                          <div>{item?.ex?.availableToBack[0]?.price}</div>
                          <div style={{fontSize: '10px'}}>
                            {item?.ex?.availableToBack[0]?.size}
                          </div>
                        </div>
                      </td>
                      <td
                        style={{width: '60px', backgroundColor: '#fbb7c6'}}
                        onClick={() => {
                          handleBettingClicked(
                            'khai',
                            index === 0
                              ? matchDetails[0]?.team1
                              : index === 1
                              ? matchDetails[0]?.team2
                              : 'The Draw',
                            item?.ex?.availableToLay[0]?.price,
                            index,
                            'odds',
                            handleOddsBetPlaced,
                          );
                        }}
                      >
                        <div>
                          <div>{item?.ex?.availableToLay[0]?.price}</div>
                          <div style={{fontSize: '10px'}}>
                            {item?.ex?.availableToLay[0]?.size}
                          </div>
                        </div>
                      </td>
                      {/* <td>{parseInt(oddsPositionArr[item.runner]) || 0}</td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const bookMakerTable = () => {
    const tiedMatch = bookMaker.find(item => item.marketName == 'Tied Match');
    const bookMakerMatch = bookMaker.find(
      item => item.marketName == 'Bookmaker',
    );
    return (
      <>
        {bookMakerMatch && (
          <div className="betting">
            {/* <h5>Bookmaker </h5> */}
            <h6>Bookmaker</h6>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: '11px',
                      width: '60%',
                      textAlign: 'left',
                      color: '#6a6a6a',
                    }}
                  >
                    Min : 100.0 | Max : 50000.0
                  </th>
                  <th
                    style={{
                      width: '20%',
                      backgroundColor: '#80c2f1',
                      fontSize: '14px',
                    }}
                  >
                    Back
                  </th>
                  <th
                    style={{
                      width: '20%',
                      background: '#fbb7c6',
                      fontSize: '14px',
                    }}
                  >
                    Lay
                  </th>
                  {/* <th>Position</th> */}
                </tr>
              </thead>
              <tbody>
                {(
                  bookMakerMatch.runners.sort(
                    (a, b) => a.sortPriority - b.sortPriority,
                  ) || []
                ).map((item, index) => {
                  // if (item.runnerName === "The Draw") return <></>;
                  return (
                    <tr>
                      <td style={{textAlign: 'left'}}>
                        {' '}
                        <div>
                          <div>{item?.runnerName}</div>
                          <div
                            style={{
                              fontSize: '10px',
                              color:
                                latestBookmakerPosition &&
                                latestBookmakerPosition[item?.runnerName] > 0
                                  ? 'green'
                                  : 'red',
                            }}
                          >
                            {latestBookmakerPosition &&
                            latestBookmakerPosition[item?.runnerName]
                              ? latestBookmakerPosition[item?.runnerName]
                              : ''}
                          </div>
                        </div>
                      </td>
                      {item.status == 'ACTIVE' ? (
                        <>
                          <td
                            style={{
                              width: '20%',
                              backgroundColor: '#80c2f1',
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                'lagai',
                                item.runnerName,
                                item?.ex?.availableToBack[0]?.price,
                                index,
                                'bookmaker',
                                handleBookMakerBetPlaced,
                              );
                            }}
                          >
                            <div>
                              <div>{item?.ex?.availableToBack[0]?.price}</div>
                              <div style={{fontSize: '10px'}}>
                                {item?.ex?.availableToBack[0]?.size}
                              </div>
                            </div>
                          </td>
                          <td
                            style={{width: '20%', background: '#fbb7c6'}}
                            onClick={() => {
                              handleBettingClicked(
                                'khai',
                                item.runnerName,
                                item?.ex?.availableToLay[0]?.price,
                                index,
                                'bookmaker',
                                handleBookMakerBetPlaced,
                              );
                            }}
                          >
                            <div>
                              <div>{item?.ex?.availableToLay[0]?.price}</div>
                              <div style={{fontSize: '10px'}}>
                                {item?.ex?.availableToLay[0]?.size}
                              </div>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={2}
                            style={{
                              color: 'red',
                              borderColor: 'white',
                              background: `url('/image/bg-disabled.png') rgba(0,0,0,.5)`,
                              fontSize: '12px',
                              fontWeight: '600',
                            }}
                          >
                            {item.status}
                          </td>
                        </>
                      )}
                      {/* <td>{parseInt(positionArr[item.runnerName]) || 0}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {tiedMatch && (
          <div className="betting">
            {/* <h5>{tiedMatch.marketName} </h5> */}
            <h6>{tiedMatch.marketName} </h6>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: '11px',
                      width: '60%',
                      textAlign: 'left',
                      color: '#6a6a6a',
                    }}
                  >
                    Min : 100.0 | Max : 50000.0
                  </th>
                  <th
                    style={{
                      width: '20%',
                      backgroundColor: '#80c2f1',
                      fontSize: '14px',
                    }}
                  >
                    Back
                  </th>
                  <th
                    style={{
                      width: '20%',
                      background: '#fbb7c6',
                      fontSize: '14px',
                    }}
                  >
                    Lay
                  </th>
                  {/* <th>Position</th> */}
                </tr>
              </thead>
              <tbody>
                {(
                  tiedMatch.runners.sort(
                    (a, b) => a.sortPriority - b.sortPriority,
                  ) || []
                ).map((item, index) => {
                  if (item.runnerName === 'The Draw') return <></>;
                  return (
                    <tr>
                      <td style={{textAlign: 'left'}}>{item.runnerName}</td>
                      {item.status == 'ACTIVE' ? (
                        <>
                          <td
                            style={{
                              width: '60px',
                              backgroundColor: '#80c2f1',
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                'lagai',
                                item.runnerName,
                                item?.ex?.availableToBack[0]?.price,
                                index,
                                'bookmaker',
                                handleBookMakerBetPlaced,
                              );
                            }}
                          >
                            <div>
                              <div>{item?.ex?.availableToBack[0]?.price}</div>
                              <div style={{fontSize: '10px'}}>
                                {item?.ex?.availableToBack[0]?.size}
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              width: '60px',
                              backgroundColor: '#fbb7c6',
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                'khai',
                                item.runnerName,
                                item?.ex?.availableToLay[0]?.price,
                                index,
                                'bookmaker',
                                handleBookMakerBetPlaced,
                              );
                            }}
                          >
                            <div>
                              <div>
                                {item?.ex?.availableToLay[0]?.price
                                  ? item?.ex?.availableToLay[0]?.price
                                  : 0}
                              </div>
                              <div style={{fontSize: '10px'}}>
                                {item?.ex?.availableToLay[0]?.size
                                  ? item?.ex?.availableToLay[0]?.size
                                  : 0}
                              </div>
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={2}
                            style={{
                              color: 'red',
                              borderColor: 'white',
                              background: `url('/image/bg-disabled.png') rgba(0,0,0,.5)`,
                              fontSize: '12px',
                              fontWeight: '600',
                            }}
                          >
                            {item.status}
                          </td>
                        </>
                      )}
                      {/* <td>{parseInt(positionArr[item.runnerName])}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  const tossTable = () => {
    return (
      <div className="toss_betting">
        {/* <h5>Toss </h5> */}
        <h6>Toss</h6>
        <table>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: '11px',
                  width: '60%',
                  textAlign: 'left',
                  color: '#6a6a6a',
                }}
              >
                Min : 100.0 | Max : 50000.0
              </th>
              <th
                style={{
                  width: '20%',
                  backgroundColor: '#80c2f1',
                }}
              >
                Yes
              </th>
              <th
                style={{
                  width: '20%',
                  background: '#fbb7c6',
                }}
              >
                No
              </th>
              {/* <th>Position</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: 'left',
                }}
              >
                {Home}
              </td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'left',
                }}
              >
                {Away}
              </td>
              <td>1</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const diamandFancyTable = () => {
    return (
      <>
        <div className="toss_betting">
          {/* <h5>Fancy </h5> */}
          <h6>
            {/* Market : Min : 100.0 | Max : 50000.0 || TotalBet :{" "}
            {-1 * totalUndeclaredBet} */}
            Fancy
          </h6>
          <table>
            <thead>
              <tr>
                <th style={{width: '60%'}}></th>
                <th style={{width: '20%'}}>No</th>
                {/* <th>Rate</th> */}
                <th style={{width: '20%'}}>Yes</th>
                {/* <th>Rate</th> */}
              </tr>
            </thead>
            <tbody>
              {diamondFancy.map(item => {
                return (
                  <>
                    {item.GameStatus == '' ? (
                      <tr>
                        <td style={{textAlign: 'left'}}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>{item?.RunnerName}</div>
                              <IconButton
                                onClick={() => {
                                  handleIconClick(item);
                                }}
                                size="small"
                                color="primary"
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            {/* <div
                              style={{
                                fontSize: '10px',
                                color:
                                  latestBookmakerPosition &&
                                  latestBookmakerPosition[item?.runnerName] > 0
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {latestBookmakerPosition &&
                              latestBookmakerPosition[item?.runnerName]
                                ? latestBookmakerPosition[item?.runnerName]
                                : ''}
                            </div> */}
                          </div>
                        </td>
                        <td
                          style={{width: '60px', backgroundColor: '#80c2f1'}}
                          onClick={() => {
                            handleFancyBetClick(
                              item.SelectionId,
                              item.RunnerName,
                              item.LayPrice1,
                              item.LaySize1,
                              item.BackPrice1,
                              item.BackSize1,
                              'No',
                              handleFancybetPlaced,
                            );
                          }}
                        >
                          <div>
                            <div>{item.LayPrice1}</div>
                            <div style={{fontSize: '10px'}}>
                              {item.LaySize1}
                            </div>
                          </div>
                        </td>
                        {/* <td>{item.LaySize1 / 100}</td> */}
                        <td
                          style={{width: '60px', backgroundColor: '#fbb7c6'}}
                          onClick={() => {
                            handleFancyBetClick(
                              item.SelectionId,
                              item.RunnerName,
                              item.LayPrice1,
                              item.LaySize1,
                              item.BackPrice1,
                              item.BackSize1,
                              'Yes',
                              handleFancybetPlaced,
                            );
                          }}
                        >
                          <div>
                            <div>{item.BackPrice1}</div>
                            <div style={{fontSize: '10px'}}>
                              {item.BackSize1}
                            </div>
                          </div>
                        </td>
                        {/* <td>{item.BackSize1 / 100}</td> */}
                      </tr>
                    ) : (
                      <tr>
                        <td style={{textAlign: 'left'}}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>{item?.RunnerName}</div>
                              <IconButton
                                onClick={handleIconClick}
                                size="small"
                                color="primary"
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            {/* <div
                              style={{
                                fontSize: '10px',
                                color:
                                  latestBookmakerPosition &&
                                  latestBookmakerPosition[item?.runnerName] > 0
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {latestBookmakerPosition &&
                              latestBookmakerPosition[item?.runnerName]
                                ? latestBookmakerPosition[item?.runnerName]
                                : ''}
                            </div> */}
                          </div>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            color: 'red',
                            borderColor: 'white',
                            background: `url('/image/bg-disabled.png') rgba(0,0,0,.5)`,
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                        >
                          {item.GameStatus}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  function calculateOversAndBalls(totalBalls) {
    // Calculate overs
    var overs = Math.floor(totalBalls / 6);
    // Calculate remaining balls
    var balls = totalBalls % 6;

    return overs + '.' + balls;
  }
  const detailedBetting = () => {
    return (
      <div
        className="session_betting"
        style={{padding: '10px 0', margin: '16px 0'}}
      >
        <h6>Session : Min : 100.0 | Max : 100000.0</h6>
        <table>
          <thead>
            <tr>
              <th>Session</th>
              <th>No</th>
              <th>Rate</th>
              <th>Yes</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {sessionBets?.map(item => (
              <tr>
                <td className="bet_cond">{item.marketName}</td>
                <td>10</td>
                <td>0.0</td>
                <td>37</td>
                <td>0.0</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const playedMatchbetsTable = () => {
    return (
      <div className="session_betting">
        <table>
          <thead>
            <tr style={{overflow: 'scroll'}}>
              {/* <th style={{ width: 150 }}>Match</th> */}
              <th
                style={{
                  backgroundColor: '#028dad',
                  color: '#fff',
                  textAlign: 'left',
                }}
              >
                Matched Bet
              </th>
              <th
                style={{
                  backgroundColor: '#028dad',
                  color: '#fff',
                  textAlign: 'left',
                }}
              >
                Odds
              </th>
              <th
                style={{
                  backgroundColor: '#028dad',
                  color: '#fff',
                  textAlign: 'left',
                }}
              >
                Stake
              </th>
            </tr>
          </thead>
          <tbody>
            {playedMatchBets?.map(item => (
              <tr
                style={{
                  backgroundColor:
                    item.bet_type === 'khai'
                      ? 'rgb(251, 183, 198)'
                      : 'rgb(128, 194, 241)',
                }}
              >
                <td style={{textAlign: 'left'}}>{item.type}</td>
                <td style={{textAlign: 'left'}}>{parseFloat(item.bet_rate)}</td>
                <td style={{textAlign: 'left'}}>{item.bet_amount}</td>
              </tr>
            ))}
            {fancySessionBet?.map(item => (
              <tr
                style={{
                  backgroundColor:
                    item.bet_type === 'Yes'
                      ? 'rgb(251, 183, 198)'
                      : 'rgb(128, 194, 241)',
                }}
              >
                <td style={{textAlign: 'left'}}>
                  {item.fancy_Detail.runnerName}
                </td>
                <td style={{textAlign: 'left'}}>{parseFloat(item.bet_rate)}</td>
                <td style={{textAlign: 'left'}}>{item.bet_amount}</td>
              </tr>
            ))}
            {playedMatchBets.length === 0 && fancySessionBet.length === 0 ? (
              <tr>
                <td
                  colSpan="100%"
                  style={{
                    textAlign: 'center',
                    padding: '15px',
                    color: '#c7313f',
                  }}
                >
                  No bets found
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };
  const playedFancyBets = () => {
    return (
      <div
        className="session_betting"
        style={{padding: '10px 0', margin: '16px 0'}}
      >
        <h5>Placed Fancy</h5>
        <h6>Total bet amount: {-1 * totalUndeclaredBet}</h6>
        <table>
          <thead>
            <tr style={{overflow: 'scroll'}}>
              <th style={{width: 150}}>Session</th>
              <th>Runs</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Mode</th>
            </tr>
          </thead>
          <tbody>
            {fancySessionBet?.map(item => (
              <tr>
                <td>{item.fancy_Detail.runnerName}</td>

                <td>
                  {item.bet_type == 'Yes'
                    ? item.fancy_Detail.backPrice1
                    : item.fancy_Detail.layPrice1}
                </td>
                <td>{parseInt(item.bet_rate) / 100}</td>
                <td>{item.bet_amount}</td>
                <td>{item.bet_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const closeBetDrawer = () => {
    setIsBettingDrawerOpen(false);
    setIsFancyBettingDrawerOpen(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Roboto Condensed'",
      }}
    >
      <div style={{maxWidth: '770px', width: '100%'}}>
        <div
          style={{
            // padding: "16px 0",
            display: 'grid',
            placeItems: 'center',
            marginBottom: '8px',
          }}
        >
          <Tabs
            value={activeTab}
            sx={{
              '.Mui-selected': {
                color: `#fff`,
                outline: 'none',
              },
              '&:hover': {
                outline: 'none',
              },
            }}
            TabIndicatorProps={{style: {background: '#18aec5'}}}
            aria-label="tabs example"
            onChange={(e, newVal) => {
              setActiveTab(newVal);
            }}
          >
            <Tab
              value={0}
              label={
                <span
                  style={{
                    color: activeTab == 0 ? 'rgb(25, 118, 210)' : '#000',
                  }}
                >
                  Scoreboard
                </span>
              }
            />
            <Tab
              value={1}
              label={
                <span
                  style={{
                    color: activeTab == 1 ? 'rgb(25, 118, 210)' : '#000',
                  }}
                >
                  Live Telecast
                </span>
              }
            />
          </Tabs>
        </div>
        {activeTab === 0 && (
          <div style={{width: '100%', objectFit: 'contain'}}>
            {/* <iframe srcDoc={liveLine} style={{ width: "100%", height: "40vh" }} /> */}
            <IframeRenderer
              url={`https://dpmatka.in/sr.php?eventid=${match_id}&sportid=${sportId}`}
              key={'test'}
            />
          </div>
        )}
        {activeTab === 1 && (
          <div style={{width: '100%', objectFit: 'contain', height: '225px'}}>
            {/* <iframe srcDoc={liveLine} style={{ width: "100%", height: "40vh" }} /> */}
            <IframeRenderer
              url={`https://dpmatka.in/dcasino/nntv.php?MatchID=${match_id}`}
              key={'test2'}
            />
          </div>
        )}
        <div style={{display: 'flex', width: '100%', height: '25px'}}>
          <button
            onClick={handleFirstButtonClick}
            style={{
              flex: 1, // Take up 50% of the width
              backgroundImage: isFirstButtonActive
                ? 'linear-gradient(#5ecbdd,#146578)'
                : 'black',
              color: 'white',
              border: 'none',
              padding: '0', // No padding to fit height
              cursor: 'pointer',
              opacity: isFirstButtonActive ? 1 : 0.5,
              height: '25px', // Set height
              display: 'flex', // Enable flexbox for centering text
              alignItems: 'center', // Center vertically
              justifyContent: 'center', // Center horizontally
            }}
          >
            Market
          </button>
          <button
            onClick={handleSecondButtonClick}
            style={{
              flex: 1, // Take up 50% of the width
              backgroundImage: isFirstButtonActive
                ? 'linear-gradient(rgb(94, 203, 221), rgb(20, 101, 120))'
                : 'linear-gradient(#5ecbdd,#146578)',
              color: 'white',
              border: 'none',
              padding: '0', // No padding to fit height
              cursor: 'pointer',
              opacity: isFirstButtonActive ? 0.5 : 1,
              height: '25px', // Set height
              display: 'flex', // Enable flexbox for centering text
              alignItems: 'center', // Center vertically
              justifyContent: 'center', // Center horizontally
            }}
          >
            Open Bets (
            {Number(playedMatchBets.length || 0) +
              Number(fancySessionBet.length || 0)}
            )
          </button>
        </div>
        <div className="tables" style={{marginBottom: '100px'}}>
          {!isFirstButtonActive ? playedMatchbetsTable() : null}
          {oddsTable()}
          {bookMaker && bookMaker[0]?.status == 'OPEN' && bookMakerTable()}
          {/* {new Date(matchStartTime) > new Date() &&
            sportId === '4' &&
            tossTable()} */}
          {sportId === '4' ? diamandFancyTable() : null}
          {/* {detailedBetting()} */}
        </div>
        <div>
          {upcomingMatches.map(item => (
            <GameListItem
              dateTime={'09 Dec, 10:00AM'}
              teams={'Ind vr Pak'}
              mBets={0}
              sBets={0}
              onClick={() => navigate('/match/' + item)}
            />
          ))}
        </div>
        <SwipeableDrawer
          open={isBettingDrawerOpen}
          anchor="bottom"
          onClose={() => setIsBettingDrawerOpen(false)}
        >
          <BetDrawer
            type={betInfo.type}
            team={betInfo.team}
            rate={betInfo.rate}
            position={positionArr[betInfo.positionIndex]}
            closeBetDrawer={closeBetDrawer}
            currState={betInfo}
            onBet={betInfo.placeBetFun}
          />
        </SwipeableDrawer>

        <SwipeableDrawer
          open={isFancyBettingDrawerOpen}
          anchor="bottom"
          onClose={() => setIsFancyBettingDrawerOpen(false)}
        >
          <BetDrawer
            type={fancyBetInfo.type}
            team={fancyBetInfo.runnerName}
            rate={
              fancyBetInfo.type == 'No'
                ? fancyBetInfo.laySize1
                : fancyBetInfo.backSize1
            }
            position={positionArr[betInfo.positionIndex]}
            closeBetDrawer={closeBetDrawer}
            currState={fancyBetInfo}
            onBet={fancyBetInfo.placeBetFun}
          />
        </SwipeableDrawer>

        <BetPrediction
          bet={selectedBet}
          open={open}
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
}
export default Match;
