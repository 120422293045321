import React from "react";
import { Box, Button, Typography, Grid, Divider } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

const MainFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#18AEC5",
        color: "#FFF",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px", // Set a minimum height for the footer
      }}
    >
      {/* Download App Section */}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxWidth: "800px",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FFF",
            color: "#000",
            padding: "10px 20px",
            borderRadius: "30px",
            fontWeight: "bold",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          DOWNLOAD APP
        </Button>
        <Box sx={{ mt: 1 }}>
          <AndroidIcon fontSize="large" sx={{ mx: 1, color: "#FFF" }} />
          <AppleIcon fontSize="large" sx={{ mx: 1, color: "#FFF" }} />
        </Box>
      </Box> */}
      <Divider sx={{ backgroundColor: "#FFF", mb: 2 }} />
      {/* Footer Description */}
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          maxWidth: "800px",
        }}
      >
        SOFTBET9 is a limited liability company incorporated under the laws of
        Curacao. Players are requested not to contact any untrusted sources for
        SOFTBET9 Exch accounts as this is an online site and they can only
        register independently without any agents. Only deposit through the
        account details generated by the system or provided by our official
        support team.
      </Typography>
      {/* WhatsApp Icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
          maxWidth: "800px",
        }}
      >
        <WhatsApp sx={{ fontSize: "50px", color: "#00E676" }} />
      </Box>
      {/* Footer Logos */}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          maxWidth: "800px",
        }}
      >
        <Grid item>
          <img
            src="/image/mainfooter/e_corga.webp"
            alt="eCOGRA"
            style={{ height: "30px" }}
          />
        </Grid>
        <Grid item>
          <img
            src="/image/mainfooter/mga.webp"
            alt="MGA"
            style={{ height: "30px" }}
          />
        </Grid>
        <Grid item>
          <img
            src="/image/mainfooter/upi.webp"
            alt="UPI"
            style={{ height: "30px" }}
          />
        </Grid>
        <Grid item>
          <img
            src="/image/mainfooter/bank_transfer.webp"
            alt="Bank Transfer"
            style={{ height: "30px" }}
          />
        </Grid>
        <Grid item>
          <img
            src="/image/mainfooter/gambling_commission.webp"
            alt="Gambling Commission"
            style={{ height: "30px" }}
          />
        </Grid>
        <Grid item>
          <img
            src="/image/mainfooter/be_gamble_aware.webp"
            alt="Be Gamble Aware"
            style={{ height: "30px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainFooter;
