import React, { useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Rules from "./Pages/Rules/Rules";
import LiveCasino from "./Pages/LiveCasino/LiveCasino";
import Inplay from "./Pages/InPlay/Inplay";
import Ledger from "./Pages/Ledger/Ledger";
import Statement from "./Pages/Statement/Statement";
import HomeLayout from "./Layout/HomeLayout";
import Match from "./Pages/InPlay/Match/Match";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import SingleMatchLedger from "./Pages/Ledger/SingleMatch/SingleMatchLedger";
import NotFound from "./Pages/NotFound/NotFound";
import Cookies from "js-cookie";
import UpcommingMatches from "./Pages/UpcommingMatches/UpcommingMatches";
import Header from "./Pages/Header/Header";
import Footer from "./Pages/Footer/Footere";
import Sidebar from "./Pages/Sidebars/Sidebars";
import MatchesList from "./Pages/Matches/match";
import MatchCard from "./Pages/Matches/matchcard";
import MyMarket from "./Pages/MyMarket/MyMarket";

const AppRoutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const token = Cookies.get("jwtToken");
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");

  // Check if the current route is the login route
  const isLoginPage = location.pathname === "/login";
  const isHomePage = location.pathname === "/";
  // const isRedirectedToLogin = !token || !isUserLoggedIn;

  return (
    <div>
      {!isLoginPage && <Header onMenuClick={toggleSidebar} />}
      {!isLoginPage && (
        <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      )}
      <Routes>
        {token && isUserLoggedIn ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/rules"
              element={<HomeLayout component={<Rules />} />}
            />
            <Route
              path="/upcoming"
              element={<HomeLayout component={<UpcommingMatches />} />}
            />
            <Route
              path="/livecasino"
              element={<HomeLayout component={<LiveCasino />} />}
            />
            <Route
              path="/inplay"
              element={<HomeLayout component={<Inplay />} />}
            />
            <Route
              path="/ledger"
              element={<HomeLayout component={<Ledger />} />}
            />
            <Route
              path="/statement"
              element={<HomeLayout component={<Statement />} />}
            />
            <Route
              path="/change-password"
              element={<HomeLayout component={<ChangePassword />} />}
            />
            <Route
              path="/match/:match_id/sport/:sport_id"
              element={<HomeLayout component={<Match />} />}
            />
            <Route
              path="/ledger/:match_id"
              element={<HomeLayout component={<SingleMatchLedger />} />}
            />
            <Route
              path="/live/:live_id"
              element={<HomeLayout component={<MatchesList />} />}
            />
            <Route
              path="/my-market"
              element={<HomeLayout component={<MyMarket />} />}
            />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
          </>
        )}
      </Routes>
      {!isLoginPage && <Footer />}
    </div>
  );
};

export default AppRoutes;
