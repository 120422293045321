import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./liveCasino.scss";

const CasinoCard = ({ casinoName, casinoImg, onClick }) => {
  return (
    <div className="casinoCard" onClick={onClick}>
      <div className="imgHolder">
        <img src={casinoImg} alt={casinoName} />
      </div>
      <div className="CTA">
        <span>{casinoName}</span>
      </div>
    </div>
  );
};

export default function LiveCasino() {
  const [casinoList, setCasinoList] = useState([
    {
      img: "https://pbs.twimg.com/media/E9sN5jzVUAUgYHn.png",
      name: "FLY Casino",
      id: 1,
    },
    {
      img: "https://www.mintface.xyz/content/images/2021/08/QmTndiF423kjdXsNzsip1QQkBQqDuzDhJnGuJAXtv4XXiZ-1.png",
      name: "TEST",
      id: 2,
    },
  ]);
  const navigate = useNavigate();
  return (
    <div className="casinoListHolder">
      {casinoList.map((item) => (
        <CasinoCard
          casinoName={item.name}
          casinoImg={item.img}
          onClick={() => navigate("/casino/" + item.id)}
        />
      ))}
    </div>
  );
}
