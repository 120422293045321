import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { BiSolidCricketBall } from "react-icons/bi";
import { GiSoccerBall } from "react-icons/gi";
import { useTheme } from "@mui/material/styles";
import { PiTennisBallFill } from "react-icons/pi";
import { MdCasino } from "react-icons/md";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import GameListItem from "../../Componant/Gamelistitem.js";
import axios from "../../authAxios.js";
import { FadeLoader } from "react-spinners";
import "./Inplay.css";
import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import MatchCard from "../Matches/matchcard.js";

export default function Inplay() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [getGames, setGetGames] = React.useState([]);
  const [isBlockedSports, setIsBlockedSports] = React.useState({});
  const blockedSports = JSON.parse(localStorage.getItem("blocked_sports"));

  const settings = {
    dots: false, // Show dots for navigation
    infinite: true, // Infinite loop sliding
    speed: 500, // Slide transition speed
    slidesToShow: 3, // Show 3 cards at a time on larger screens
    slidesToScroll: 1, // Slide one card at a time
    autoplay: true, // Enable auto slide
    autoplaySpeed: 3000, // Set auto slide interval (in milliseconds)
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Define the max-width for mobile devices (768px is a common mobile breakpoint)
        settings: {
          slidesToShow: 1, // Show only 1 card on mobile
          slidesToScroll: 1, // Slide one card at a time
        },
      },
    ],
  };

  const bonus = [
    { name: "BONUS1", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS2", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS3", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS4", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
    { name: "BONUS5", link: "/bonus", bgImg: "/image/bonus/bonus.jpg" },
  ];

  const [cricketMatches, setCricketMatches] = useState([]);
  const [soccerMatches, setSoccerMatches] = useState([]);
  const [tennisMatches, setTennisMatches] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const [matches, setMatches] = useState([]);
  const [sports_id, setSports_id] = useState(4);
  const [seriesList, setseriesList] = useState([]);
  const [series_Id, setseries_Id] = useState(null);
  const [loading, setloading] = useState(false);

  const BonusPlayOption = ({ bonus, onClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Card
        onClick={() => onClick(bonus.link)}
        sx={{
          width: isMobile ? "97%" : "100%", // Adjust width based on screen size
          height: isMobile ? "160px" : "110px", // Adjust height for mobile
          marginLeft: "10px",
          backgroundImage: `url(${bonus.bgImg})`,
          backgroundColor: "gray",
          backgroundSize: "cover",
          backgroundPosition: "center",
          border: "0px solid white",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          padding: "0px",
          cursor: "pointer",
        }}
      >
        <Box sx={{ flexGrow: 1 }}></Box>{" "}
        {/* Empty box to push content to the bottom */}
      </Card>
    );
  };

  useEffect(() => {
    const inplayMatchesForAllSports = async (sports_id) => {
      setloading(true);
      setMatches([]);
      try {
        let res = await axios.get("/matches/getInplayMatches");
        if (res.data.status) {
          setCricketMatches(res.data.data.cricket);
          setSoccerMatches(res.data.data.soccer);
          setTennisMatches(res.data.data.tennis);
          // console.log("res matches", matches);
        }
        setloading(false);
      } catch (err) {
        console.log(err);
        setloading(false);
      }
    };

    inplayMatchesForAllSports();
  }, []);

  useEffect(() => {
    const getGamesId = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setGetGames(result?.data?.dataobj);
      } catch (err) {
        console.log(err);
      }
    };
    getGamesId();
  }, []);

  useEffect(() => {
    if (getGames.length > 0 && blockedSports) {
      const blockedSportsObj = getGames.reduce((acc, game) => {
        const blockedStatus = blockedSports.find(
          (sport) => sport.sportId === game._id
        );
        acc[game.gName] = blockedStatus ? blockedStatus.isBlocked : false;
        return acc;
      }, {});
      setIsBlockedSports(blockedSportsObj);
    }
  }, [getGames]);

  const bonusCards = () => {
    return bonus.map((bonus, index) => (
      <BonusPlayOption key={index} bonus={bonus} onClick={() => {}} />
    ));
  };
  return (
    <Box
      sx={{
        width: "auto",
        height: "auto",
        paddingBottom: "100px",
        paddingTop: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box sx={{ width: isMobile ? "100%" : "800px", margin: "0 auto" }}>
        <Box sx={{ paddingLeft: isMobile ? "" : "22px" }}>
          <Slider {...settings}>
            {bonusCards()
              .concat(bonusCards())
              .map((card, index) => (
                <Box
                  key={index}
                  className="scroll-container-card"
                  sx={{
                    width: "100%", // Set the card width (adjust based on your design)
                    // backgroundColor: "#fff", // Adjust background color
                    padding: "0px 10px 0px 0px",
                    borderRadius: "10px",
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {card} {/* Render each bonus card */}
                </Box>
              ))}
          </Slider>
        </Box>

        {/* Match Cricket Live */}
        {!isBlockedSports.Cricket && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "-12px",
              }}
            >
              {/* Left Section: Cricket Title and See All Button */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Cricket
                </Typography>

                {/* See All Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px", // Add margin for spacing
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight white background on hover
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/4")}
                >
                  See All
                </Button>
              </Box>

              {/* Right Section: 1X2 Labels Box */}
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "280px", // Fixed width for the 1X2 labels
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Cricket Matches */}
            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {cricketMatches.map((match, index) => (
                <MatchCard key={index} match={match} />
              ))}
            </Box>
          </Box>
        )}

        {/* Match Soccer Live */}
        {!isBlockedSports.Soccer && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Aligns content to the sides
                marginBottom: "-12px",
              }}
            >
              {/* Left Section: Soccer Title and See All Button */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Soccer
                </Typography>
                {/* See All Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px", // Add margin for spacing
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight white background on hover
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/1")}
                >
                  See All
                </Button>
              </Box>

              {/* Right Section: 1X2 Labels Box */}
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "280px", // Fixed width for the 1X2 labels
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Soccer Matches */}
            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {soccerMatches.map((match, index) => (
                <MatchCard key={index} match={match} />
              ))}
            </Box>
          </Box>
        )}

        {/* Match Tennis Live */}
        {!isBlockedSports.Tennis && (
          <Box
            sx={{
              marginBottom: "0px",
              padding: isMobile ? "10px 0px 0px 0xpx" : "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Aligns content to the sides
                marginBottom: "-12px",
              }}
            >
              {/* Left Section: Tennis Title and See All Button */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    pt: "10px",
                    pl: isMobile ? "" : "12px",
                  }}
                >
                  Tennis
                </Typography>
                {/* See All Button */}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    fontSize: "12px",
                    color: "primary.main",
                    border: "0px solid",
                    borderColor: "primary.main",
                    boxShadow: "none",
                    marginLeft: "10px", // Add margin for spacing
                    paddingTop: "20px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight white background on hover
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => navigate("/live/2")}
                >
                  See All
                </Button>
              </Box>

              {/* Right Section: 1X2 Labels Box */}
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    pt: "10px",
                    justifyContent: "space-between",
                    width: "280px", // Fixed width for the 1X2 labels
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    X
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ width: "33%", textAlign: "center" }}
                  >
                    2
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Tennis Matches */}
            <Box sx={{ paddingLeft: "2px", width: "100%" }}>
              {tennisMatches.map((match, index) => (
                <MatchCard key={index} match={match} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
