import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { rules } from '../../constants';

function RulesPopup({open, handleClose, type}) {
  console.log(type);
  return (
    <div>
      {/* Dialog for Terms and Conditions */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{`${type} `}Rules</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: rules[type] || '<p>No rules available.</p>' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RulesPopup;
