import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Sidebars.scss";
import axios from "../../authAxios";

const Sidebar = ({ isOpen, onClose }) => {
  const [getGames, setGetGames] = React.useState([]);
  const [isBlockedSports, setIsBlockedSports] = React.useState({});
  const blockedSports = JSON.parse(localStorage.getItem("blocked_sports"));

  useEffect(() => {
    const getGamesId = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setGetGames(result?.data?.dataobj);
      } catch (err) {
        console.log(err);
      }
    };
    getGamesId();
  }, []);

  useEffect(() => {
    if (getGames.length > 0 && blockedSports) {
      const blockedSportsObj = getGames.reduce((acc, game) => {
        const blockedStatus = blockedSports.find(
          (sport) => sport.sportId === game._id
        );
        acc[game.gName] = blockedStatus ? blockedStatus.isBlocked : false;
        return acc;
      }, {});
      setIsBlockedSports(blockedSportsObj);
    }
  }, [getGames]);

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box sx={{ width: 250, backgroundColor: "#18AEC5", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            backgroundColor: "#18AEC5", // Apply background color here as well
            color: "#FFF",
          }}
        >
          <Typography variant="h6">SOFTBET9</Typography>
          <IconButton onClick={onClose} sx={{ color: "#FFF" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ backgroundColor: "#FFF" }} />

        <List sx={{ color: "#FFF" }}>
          {[
            { text: "Home", to: "/" },
            {
              text: "Inplay",
              to: "/inplay",
            },
            // {
            //   text: "Sportsbook",
            //   // to: "/sportsbook"
            // },
            {
              text: "My Statement",
              to: "/statement",
            },
            {
              text: "Cricket",
              to: isBlockedSports?.Cricket ? "#" : "/live/4",
              disabled: isBlockedSports?.Cricket,
            },
            {
              text: "Soccer",
              to: isBlockedSports?.Soccer ? "#" : "/live/1",
              disabled: isBlockedSports?.Soccer,
            },
            {
              text: "Tennis",
              to: isBlockedSports?.Tennis ? "#" : "/live/2",
              disabled: isBlockedSports?.Tennis,
            },
            {
              text: "Change Password",
              to: "/change-password",
            },
          ].map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={item.to}
              onClick={onClose}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style: { color: item.disabled ? "#D2D2D2" : "#FFF" },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
