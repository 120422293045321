import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

export const BetPrediction = ({bet, open, onClose}) => {
  if (!bet) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Exposure</DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h6" color="textSecondary">
            No bets found
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const {bet_rate: betRate, bet_amount: betAmount, bet_type: betType} = bet;

  const calculateOutcome = outcome => {
    let result, amount;
    const winPercentage = 0.01; // Assuming 1% winning rate

    if (betType === 'Yes') {
      if (outcome >= betRate) {
        result = 'Win';
        amount = (winPercentage * betAmount * 100).toFixed(2);
      } else {
        result = 'Lose';
        amount = (-betAmount).toFixed(2);
      }
    } else {
      if (outcome < betRate) {
        result = 'Win';
        amount = (winPercentage * betAmount * 100).toFixed(2);
      } else {
        result = 'Lose';
        amount = (-betAmount).toFixed(2);
      }
    }

    return {result, amount};
  };

  const outcomes = [betRate - 1, betRate, betRate + 1];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Exposure</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Outcome</TableCell>
              <TableCell align="center">Result</TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outcomes.map((outcome, index) => {
              const {result, amount} = calculateOutcome(outcome);
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: result === 'Win' ? '#add8e6' : '#f8d7da',
                  }}
                >
                  <TableCell align="center">{outcome}</TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: result === 'Win' ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {result}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: result === 'Win' ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {amount}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
