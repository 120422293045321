import React from "react";
import { DataGrid } from "@mui/x-data-grid"; // Import the DataGrid component from @mui/x-data-grid

function Table({ data, columns, title, ...rest }) {
  // Convert columns to DataGrid format
  const gridColumns = columns.map((col) => ({
    field: col.selector,
    headerName: col.name,
    width: col.width,
    ...col.options,
  }));

  // Add a unique id property to each row in the data array
  const updatedData = data.map((row, index) => ({
    id: index, // Use the index as the unique id
    ...row,
  }));

  return (
    <div
      style={{
        borderRadius: "8px",
        margin: "8px 0",
        backgroundColor: "#fff",
        // overflowX: "auto", // This allows horizontal scrolling for the table
        // maxWidth: "100%", // Ensures it doesn't overflow the parent container
      }}
    >
      <DataGrid
        title={title}
        rows={updatedData}
        columns={gridColumns}
        {...rest}
      />
    </div>
  );
}

export default Table;
