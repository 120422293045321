import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  useMediaQuery,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import axios from '../../authAxios';

export default function MatchCard({match, sport_id}) {
  const navigate = useNavigate();
  const startTime = match?.start_time || 'N/A';
  const id = match?.match_id || 'N/A';
  const team1 = match?.team1 || 'Team 1';
  const team2 = match?.team2 || 'Team 2';
  const inplay = match?.odds[0]?.inplay;
  const [odds, setOdds] = useState([]); // Example odds
  const [market_id, setMarketId] = useState();
  const [market_odds, setMarketOdds] = useState();
  const boxRef = useRef(null);
  const formattedDateTime =
    startTime !== 'N/A'
      ? new Date(startTime).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
      : 'N/A';

  // Detect mobile view
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    console.log(match);
    const newOdds = match.odds.map(item => {
      const oddsArray = [
        item?.runners[0]?.ex?.availableToBack[0]?.price || 0,
        item?.runners[0]?.ex?.availableToBack[1]?.price || 0,
        item?.runners[0]?.ex?.availableToBack[2]?.price || 0,
        item?.runners[0]?.ex?.availableToLay[0]?.price || 0,
        item?.runners[0]?.ex?.availableToLay[1]?.price || 0,
        item?.runners[0]?.ex?.availableToLay[2]?.price || 0,
      ];

      return oddsArray;
    });
    console.log('new oddsss', newOdds);
    setOdds(newOdds[0]);
  }, [match]);

  useEffect(() => {
    const box = boxRef.current;

    const handleScroll = event => {
      if (event.deltaY !== 0) {
        event.preventDefault(); // Prevent vertical scroll
        box.scrollLeft += event.deltaY; // Scroll horizontally
      }
    };

    if (box) {
      box.addEventListener('wheel', handleScroll);
    }

    // Cleanup event listener on unmount
    return () => {
      if (box) {
        box.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <Card
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '5px',
        margin: '10px 0',
        background: 'white',
      }}
      onClick={() => navigate(`/match/${id}/sport/${sport_id}`)}
    >
      <CardContent
        sx={{
          // border: "1px solid #e0e0e0",
          // borderRadius: "5px",
          padding: isMobile ? '16px 0px 0px 0px' : '16px',
          // background: "white",
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Match Information */}
          <Grid item xs={12} md={8}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {inplay ? (
                <div
                  class="lvnow"
                  style={{
                    backgroundColor: '#6bbd11',
                    borderRadius: '50%',
                    height: '10px',
                    width: '10px',
                    marginRight: '16px',
                  }}
                ></div>
              ) : null}
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="700"
              >
                {formattedDateTime}
              </Typography>
            </div>
            <Typography variant="subtitle1" fontWeight="700">
              {team1} VS {team2}
            </Typography>
          </Grid>

          {/* Odds Section */}
          <Grid item xs={12} md={4} sx={{mt: isMobile ? 2 : 0}}>
            {isMobile ? (
              // Mobile View: Same as before, odds below the team names
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap', // Single row for mobile
                  overflowX: 'none',
                }}
              >
                {odds && odds.length > 0 ? (
                  odds.slice(0, 6).map((odd, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{
                        flex: 1,
                        margin: '1px',
                        backgroundColor:
                          index % 2 === 0 ? '#80C2F1' : '#FBB7C6', // Even/Odd color
                        color: '#000',
                        fontSize: '14px',
                        fontWeight: '700',
                        minWidth: 'auto',
                        height: '24px',
                      }}
                    >
                      {odd}
                    </Button>
                  ))
                ) : (
                  <p>No odds available</p>
                )}
              </Box>
            ) : (
              // Desktop View: Display labels and scrollable buttons in a row
              <>
                {/* Scrollable buttons below */}
                <Box
                  ref={boxRef}
                  sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap', // Prevent buttons from wrapping to a new line
                    justifyContent: 'space-between', // Keep buttons spread out

                    '&::-webkit-scrollbar': {
                      display: 'none', // For Chrome, Safari, and Edge
                    },
                    msOverflowStyle: 'none',
                  }}
                >
                  {odds && odds.length > 0 ? (
                    odds.map((odd, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        sx={{
                          minWidth: '63px', // Control the width of the buttons
                          height: '30px', // Control height of buttons
                          margin: '4px',
                          backgroundColor:
                            index % 2 === 0 ? '#80C2F1' : '#FBB7C6', // Even/Odd color
                          color: '#000',
                          fontWeight: '700',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                      >
                        {odd}
                      </Button>
                    ))
                  ) : (
                    <p style={{textAlign: 'center'}}>No odds available</p>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
